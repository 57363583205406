import React from 'react';
import './FilledCheckbox.scss';

const FilledCheckbox = (props) => {
  const {
    disabled,
    name,
    label,
    className,
  } = props;

  return (
    <div
      htmlFor={`${name} ${label}`}
      className={`filled-checkbox ${className}`}
    >
      {label && <p>{label}</p> }
      <input disabled={disabled} type="checkbox" checked />
      <span className="checkmark" />
    </div>
  );
};

export default FilledCheckbox;
