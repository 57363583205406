import React, { useState } from 'react';
import SlidingCard from '../SlidingCard/SlidingCard';
import './Slider.scss';

const Slider = (props) => {
  const {
    slides,
  } = props;
  const [localSlides, setLocalSlides] = useState(slides);
  const Z_DIS = -15;
  const Y_DIS = -10;
  const X_DIS = 7;
  let x = 0;
  let y = 0;
  let z = 0;

  const onCLickCallback = () => {
    const arrayWithoutFirst = [...localSlides];
    const first = arrayWithoutFirst[0];
    arrayWithoutFirst.shift();
    setLocalSlides([...arrayWithoutFirst, first]);
  };

  return (
    <div className="slider">
      {localSlides.map((slide, ind) => {
        const style = { transform: `translateZ(${z}px) translateY(${y}px) translateX(${x}px)` };

        z += Z_DIS;
        y += Y_DIS;
        x += X_DIS;

        return (
          <SlidingCard
            key={slide.id}
            iconUrl={slide.iconUrl}
            mainText={slide.mainText}
            additionalBottomText={slide.additionalBottomText}
            width={slide.width}
            height={slide.height}
            style={style}
            onClick={ind === 0
              ? () => onCLickCallback()
              : () => {}}
          />
        );
      })}
    </div>
  );
};

export default Slider;
