import React from 'react';
import HubspotForm from 'react-hubspot-form';
import FilledCheckbox from '../components/FilledCheckbox/FilledCheckbox';
import Slider from '../components/Slider';
import Layout from '../layout';
import './book-a-demo.scss';
import slgLogo from '../../static/images/organizations/SLG-Logo.jpg';
import saabLogo from '../../static/images/organizations/Saab-Logo.jpg';
import esaLogo from '../../static/images/successStories/esa.png';

const BookADemo = (props) => {
  const {
    location,
  } = props;
  return (
    <Layout pathname={location.pathname} withWedges={false} className="home-page" backgroundEnabled="disabled">
      <div className="book-a-demo-page">
        <div className="book-a-demo-page-left-part">
          <div className="book-a-demo-page-left-part-content">
            <h3 className="title">
              Reach out
            </h3>
            <p className="text-1">
              Notice: We are soon discontinuing our services, as MLReef is being in the process of
              being acquired. You can still reach out but we will not be able to showcase our platform.
            </p>
            <div className="book-a-demo-page-left-part-content-cards">
              <Slider
                slides={[
                  {
                    id: 'slide 2',
                    iconUrl: slgLogo,
                    mainText: `Thanks to the intuitive and easy handling of the platform, 
                    domain experts can develop and use the model, 
                    even if they do not come from a data science background`,
                    additionalBottomText: '- Philip Tutto, Managing Director & Partner',
                    width: 50,
                    height: 50,
                  },
                  {
                    id: 'slide 1',
                    iconUrl: saabLogo,
                    mainText: `At Saab we saw the need for operationalizing our Machine Learning
                    efforts and to include more engineers into the ML life cycle.
                    With MLReef we have a tool that deliver this`,
                    additionalBottomText: '- Sven Nielson, XInnovation Lab, Saab Group',
                    width: 60,
                    height: 40,
                  },
                  {
                    id: 'slide 3',
                    iconUrl: esaLogo,
                    mainText: `Working with Earth Observation data is complex and requires
                    a high degree of domain expertise. MLReef offers a way to enable data 
                    scientists and domain experts to speak the same language.`,
                    additionalBottomText: '- Giuseppe Borghi, Φ-lab Lab, European Space Agency (ESA)',
                    width: 50,
                    height: 50,
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="book-a-demo-page-right-part">
          <div className="book-a-demo-page-right-part-content border-rounded">
            {/*   <p className="title">Solve your Machine Learning collaboration</p>
            <label className="email-lbl" htmlFor="email-input">
              work email
              <input type="text" name="email-input" />
            </label>
            <p className="email-desc">Must be valid email. example@yourdomain.com</p>
            <p className="product-desc">
              By providing your contact information,
              you agree that MLReef may contact you via email about
              its product, services, and events.
              You may opt-out at any time by unsubscribing in emails
            </p>
            <button type="button" className="submit-button">
              Schedule Intro Call
            </button> */}
            <HubspotForm
              portalId="6274656"
              formId="a1376c5c-770e-4142-b791-9ffd90125440"
              onSubmit={() => console.log('Submit!')}
              onReady={(form) => console.log('Form ready!')}
              loading={<div>Loading...</div>}
            />
          </div>
          <div className="bottom-texts">
            <div className="bottom-texts-row">
              <div className="checkbox-container">
                <FilledCheckbox />
              </div>
              <div className="texts-container">
                <p>
                  <b>Your investment:</b> 20 min call.
                </p>
                <p>
                  Outline your pain points and we tell you if MLReef can solve these.
                </p>
              </div>
            </div>

            <div className="bottom-texts-row">
              <div className="checkbox-container">
                <FilledCheckbox />
              </div>
              <div className="texts-container">
                <p>
                  <b>Your return:</b> Find out which data setup is required for your needs.
                </p>
                <p>
                  You'll learn whether MLReef solves your data needs
                  or if there are more suitable tools out there.
                </p>
                <p>
                  No annoying follow-ups if we believe
                  that MLReef cannot add significant value to your business.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BookADemo;
