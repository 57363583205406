import { string } from 'prop-types';
import React from 'react';
import './Avatar.scss';

const Avatar = (props) => {
  const {
    img,
    width,
    height,
  } = props;
  return (
    <div className="reusable-avatar">
      <img src={img} alt="" width={width} height={height} />
    </div>
  );
};

Avatar.propTypes = {
  img: string.isRequired,
  width: string,
};

Avatar.defaultProps = {
  width: '40',
};

export default Avatar;
