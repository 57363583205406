import React from 'react';
import Avatar from '../Avatar';
import './SlidingCard.scss';
import reverseQuotions from '/static/images/reverseQuotationMarks.png'

const SlidingCard = (props) => {
  const {
    iconUrl,
    width,
    height,
    mainText,
    additionalBottomText,
    style,
    onClick,
  } = props;
  return (
    <div
      className="sliding-card"
      style={style}
      onClick={() => {
        if (typeof onClick === 'function') {
          onClick();
        }
      }}
    >
      <Avatar img={iconUrl} width={width || '65'} height={height || '40'} />
      <img className="quotation-marks" src={reverseQuotions} alt="" width="20" />
      <p className="main-text">
        {mainText}
      </p>
      <p className="secondary-text">
        {additionalBottomText}
      </p>
    </div>
  );
};

export default SlidingCard;
